<template>
    <div class="col-sm-12">
      <apexchart width="100%" height='400px' type="pie" :options="chartOptions" :series="Series"></apexchart>
    </div>
 </template>
 <script>
     function chartOptions(){
         return {
            chart: {
                type: 'pie',
                animations: this.Animations,
            },
            title: {
                text: this.$t('label.ErrorsDistribution'),
                align: 'center',
            },
            colors: this.Colors,
            labels: this.Categories,
            legend: {
                show: true,
                formatter: function(seriesName, opts) {
                    let sum = 0;
                    let dataArr = opts.w.globals.seriesTotals;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (dataArr[opts.seriesIndex]*100 / sum).toFixed(2)+"%";
                    return percentage+' '+seriesName;
                },
            },
            tooltip: this.Tooltip,
            plotOptions: {
                pie: {
                    expandOnClick: true,
                },
            },
            dataLabels: {
                enabled: false,
                dropShadow: {
                    enabled: false,
                },
                formatter: function(val) {
                    return val.toFixed(2)+'%';
                },
            },
         }    
     }

     export default {
         name:'simplePie',
          props: {
            Series: {
                 type: Array,
                 require: true,
                 default: () => [],
            },
            Categories:{
                 type: Array,
                 require: true,
                 default: () => [],
            },
            Colors:{
                type: Array,
                require: false,
                default: () => [],
            },
            Tooltip:{
                type: Object,
                require: false,
            },
            Animations:{
                type: Boolean,
                require: true,
            },
         },
         methods:{},
         computed:{
             chartOptions
         },
     }
 </script>